<template>
  <div class="iq-feature10">
    <div class="left">
      <slot name="cardMedia"></slot>
    </div>
    <div class="right">
      <slot name="cardBody"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardStyle4'
}
</script>
