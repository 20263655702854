// import JSEncrypt from 'jsencrypt'

export default class RsaUtils {
  static encryptedData (publicKey, data) {
    // global.navigator = { appName: 'nodejs' } // fake the navigator object
    // global.window = {}
    // // const JSEncrypt = require('jsencrypt').default
    // // New JSEncrypt object
    if (process.browser) {
      const JSEncrypt = require('jsencrypt').default
      const encryptor = new JSEncrypt()
      // Setting public key
      encryptor.setPublicKey(publicKey)
      // Encrypted data
      return encryptor.encrypt(data)
    }
  }

  static decryptData (privateKey, data) {
    // global.navigator = { appName: 'nodejs' } // fake the navigator object
    // global.window = {}
    // // const JSEncrypt = require('jsencrypt').default
    // // New JSEncrypt object
    // const decrypt = new JSEncrypt()
    // // Set private key
    // decrypt.setPrivateKey(privateKey)
    // // Declassified data
    // return decrypt.decrypt(data)
  }
}
