<template>
  <footer :class="footerClass">
    <div class="container">
      <slot name="footerContent"/>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'FooterMain',
  // eslint-disable-next-line vue/require-prop-types
  props: ['footerClass']
}
</script>
