<template>
  <FooterMain footer-class="iq-footerr iq-pt-40 iq-pb-20">
    <div slot="footerContent">
      <div class="row justify-content-center">
        <img id="logo-img" :src="require('../../assets/images/logo.png')" style="max-width: 100%; height: auto">
      </div>
      <p style="font-size: 35px; line-height: 35px; text-align: center;">
        <a href="https://www.facebook.com/mindtheappsrl/"><i class="fab fa-facebook" /></a>
        <a href="https://www.linkedin.com/company/mind-the-app-srl"><i class="fab fa-linkedin" /></a>
      </p>
      <div style="font-weight: 300; margin:auto;text-align: center;bottom:0">
        <p>Mind the App S.r.l.  - Partita IVA: 04813770759</p>
        <p style="font-weight: 500">
          <a href="mailto:info@mindtheapp.it">info@mindtheapp.it</a>
        </p>
      </div>
      <hr>
      <div class="row iq-mt-20 align-items-center justify-content-center">
        <div class="col-auto mr-auto">
          <ul class="link">
            <li class="d-inline-block iq-mr-10">
              <NuxtLink :to="'/tec'">
                <a href="javascript:void(0)">Termini e Condizioni</a>
              </NuxtLink>
            </li>
            <li class="d-inline-block">
              <NuxtLink :to="'/privacy'">
                <a href="javascript:void(0)"> Privacy Policy</a>
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div class="col-auto ">
          <div class="iq-copyright iq-ml-10">
            <a href="javascript:void(0)"> <NuxtLink :to="'/note-legali'">Note Legali</NuxtLink></a> © {{ new Date().getFullYear() }} Mind The App</a>
          </div>
        </div>
      </div>
    </div>
  </FooterMain>
</template>

<script>
import FooterMain from '../sofbox/Partials/Footer/FooterMain'
export default {
  name: 'Footer',
  components: { FooterMain },

  data () {
    return {
      footerText: [
        {
          icon: true,
          iconClass: 'ion-ios-location-outline',
          text: '1234 North Luke Lane, South Bend, IN 360001'
        },

        {
          icon: true,
          iconClass: 'ion-ios-telephone-outline',
          text: '+0123 456 789'
        },

        {
          icon: true,
          iconClass: 'ion-ios-email-outline',
          text: 'mail@sofbox.com'
        }
      ],

      footerPlatform: [
        {
          section: [
            {
              href: 'javascript:void(0)',
              title: 'Linux'
            },
            {
              href: 'javascript:void(0)',
              title: 'Windows'
            },
            {
              href: 'javascript:void(0)',
              title: 'Web'
            },
            {
              href: 'javascript:void(0)',
              title: 'Android'
            }
          ]
        }
      ],

      footerHome: [
        {
          section: [
            {
              href: 'javascript:void(0)',
              title: 'Home'
            },
            {
              href: 'javascript:void(0)',
              title: 'About us'
            },
            {
              href: 'javascript:void(0)',
              title: 'Our team'
            },
            {
              href: 'javascript:void(0)',
              title: 'Portfolio'
            }
          ]
        }

      ],

      footerCommunity: [
        {
          section: [
            {
              href: 'javascript:void(0)',
              title: 'Knowledge'
            },
            {
              href: 'javascript:void(0)',
              title: 'Developers'
            },
            {
              href: 'javascript:void(0)',
              title: 'FAQ'
            },
            {
              href: 'javascript:void(0)',
              title: 'Forum'
            }
          ]
        }
      ],

      footerCompany: [
        {
          section: [
            {
              href: 'javascript:void(0)',
              title: 'About'
            },
            {
              href: 'javascript:void(0)',
              title: 'Blog'
            },
            {
              href: 'javascript:void(0)',
              title: 'Press'
            },
            {
              href: 'javascript:void(0)',
              title: 'Careers'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
