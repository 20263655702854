<template>
  <!-- Header -->
  <header id="main-header" :class="className" style="z-index: 1000000">
    <slot name="topBar" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <nav class="navbar expand navbar-light">
            <a class="navbar-brand" href="/">
              <img :id="styledLogo ? 'logo_img' : ''" :src="logoImg" class="img-fluid" alt="#">
            </a>
            <button
              id="btnNavbar"
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="ion-navicon" />
            </button>
            <div id="navbarSupportedContent" class="collapse navbar-collapse">
              <ul class="navbar-nav mr-auto w-100 justify-content-end menu">
                <li v-for="(option, index) in navItemList" :key="index" class="nav-item menu-item">
                  <NuxtLink v-if="option.href.indexOf('#') > -1" :to="option.href" exact-active-class="active" class="nav-link" @click.native="openChatOnEndScroll(option.href)">
                    {{ option.title }}
                    <i v-if="option.children" class="fa fa-angle-down toggledrop" aria-hidden="true" />
                  </NuxtLink>
                  <a v-else :href="option.href" exact-active-class="active" class="nav-link">
                    <span>{{ option.title }}</span>
                  </a>
                  <!-- <a :class="win.location.hash && option.href == win.location.hash ? ' active ' : ''" :href="option.href" @click="jumpTo(option.href)" class="nav-link">
                    {{ option.title }}
                    <i v-if="option.children" class="fa fa-angle-down toggledrop" aria-hidden="true" />
                  </a> -->
                  <ul v-if="option.children" class="sub-menu" style="display: none;">
                    <li
                      v-for="(child, idx) in option.child"
                      id="menu-item-506"
                      :key="idx"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-506"
                    >
                      <nuxt-link v-if="child.href.indexOf('#') > -1" :to="child.href">
                        <span>{{ child.title }}</span>
                      </nuxt-link>
                      <a v-else :href="child.href">
                        <span>{{ child.title }}</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <slot name="navContent" />
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>
  <!-- Header End -->
</template>

<script>
export default {
  name: 'HeaderStyle1',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    className: String,
    // eslint-disable-next-line vue/require-default-prop
    logoImg: String,
    // eslint-disable-next-line no-undef,vue/require-default-prop
    navItemList: Array,
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-types
    styledLogo: { Boolean: true }
  },
  data () {
    return {
      win: window
    }
  },
  created () {
    this.$root.$on('bv::scrollspy::activate', this.onActivate)
  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
    onActivate (target) {
      console.log('Received event: "bv::scrollspy::activate" for target ', target)
    },
    openChatOnEndScroll (href) {
      $('#navbarSupportedContent').collapse('hide')
      if (href === '#contact_us') {
        window.tiledesk.open()
      }
    }
  }
}
</script>

<style lang="scss">
/* @media (min-width: 1447px) {
  header .navbar.navbar-expand-lg .navbar-nav .nav-item a.nuxt-link-active {
    color: white!important;
  }
} */

@media (min-width: 1447px) {
  .expand {
    flex-flow: row nowrap;
    justify-content: flex-start;

    .navbar-nav {
      flex-direction: row;

      .dropdown-menu {
        position: absolute;
      }
    }
  }

  .navbar-collapse {
    display: flex !important; // stylelint-disable-line declaration-no-important

    // Changes flex-bases to auto because of an IE10 bug
    flex-basis: auto;
  }

  .navbar-toggler {
    display: none;
  }
}
</style>
