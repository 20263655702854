import LoginSystemModel from '~/models/auth/LoginSystemModel'
import RsaUtils from '~/utils/rsaUtils'

export default class LoginSystemUtils {
  static getLoginSystemCredentials(existingPass) {
    return new LoginSystemModel({
      user: process.env.systemUserId,
      password:
        existingPass ||
        RsaUtils.encryptedData(process.env.publicKey, new Date().toISOString()),
    })
  }
}
