import LoginSystemUtils from '~/utils/auth/loginSystemUtils'

export const state = () => ({
  article: null,
  articles: [],
  totalArticles: 0
})

export const getters = {
  getArticle: state => state.article,
  getArticles: state => state.articles,
  getTotalArticles: state => state.totalArticles
}

function getAnonArticlesPromise (axios, categoryId, currentPage, perPage) {
  const filter = {
    password: LoginSystemUtils.getLoginSystemCredentials().password,
    categoryId,
    currentPage,
    perPage
  }
  return axios.post(
    `${process.env.baseUrl}/Articles/anon/articlesByFilter`,
    filter
  )
}

function getArticlesPromise (axios, commit, categoryId, currentPage, perPage) {
  return new Promise((resolve, reject) => {
    getAnonArticlesPromise(axios, categoryId, currentPage, perPage)
      .then((response) => {
        commit('setArticles', response.data.result.items)
        commit('setTotalArticles', response.data.result.totalItems)
        resolve(response.data.result)
      }, (error) => {
        commit('setArticles', [])
        commit('setTotalArticles', 0)
        reject(error)
      })
      .catch((error) => {
        commit('setArticles', [])
        commit('setTotalArticles', 0)
        reject(error)
      })
  })
}

export const actions = {
  loadPortfolioArticles ({ commit }, { currentPage, perPage }) {
    return getArticlesPromise(
      this.$axios,
      commit,
      process.env.categories.portfolio.id,
      currentPage,
      perPage
    )
  },
  loadArticleWithAnon ({ commit }, { idCmsArticle }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`${process.env.baseUrl}/Articles/anon/${idCmsArticle}`)
        .then((response) => {
          commit('setArticle', response.data.result)
          resolve(response.data.result)
        })
        .catch((error) => {
          commit('setArticle', null)
          reject(error)
        })
    })
  },
  sendFormAnswers ({ commit }, data) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`${process.env.baseUrl}/Articles/formAnswer`, data)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export const mutations = {
  setArticle (state, article) {
    state.article = article
  },
  setArticles (state, articles) {
    state.articles = articles
  },
  setTotalArticles (state, totalArticles) {
    state.totalArticles = totalArticles
  }
}
