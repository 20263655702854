<template>
  <div>
    <div id="mainDiv" data-spy="scroll" data-offset="80" class="hide-overflow-x">
      <Loader />
      <HeaderStyle1 id="header" :logo-img="logo" :nav-item-list="navItems" :styled-logo="styleLogo">
      <!-- <a slot="navContent" href="#" class="button bt-black pull-right">Login</a> -->
      </HeaderStyle1>
      <nuxt />
    </div>
    <Footer />
    <ScrollTop />
  </div>
</template>

<script>
import Footer from '@/components/Homepage/Footer'
import logoImg from '../assets/images/logo.png'

export default {
  name: 'Page',
  components: {
    Footer
  },
  head () {
    return {
      bodyAttrs: {
        'data-spy': 'scroll',
        'data-offset': '80'
      }
    }
  },
  data () {
    return {
      styleLogo: true,
      logo: logoImg,
      navItems: [
        { href: '/#homepage', title: 'Home' },
        { href: 'https://www.linkedin.com/company/mind-the-app-srl/jobs/', title: 'Jobs' },
        // { href: '/blog', title: 'Blog' },
        // { href: '/portfolio', title: 'Portfolio' },
        { href: '/#what_we_do', title: 'Cosa facciamo' },
        { href: '/#products', title: 'I nostri prodotti' },
        { href: '/#why_choose_us', title: 'Perchè sceglierci' },
        { href: '/#technologies', title: 'Tecnologie' },
        { href: '/#clients_and_partners', title: 'Clienti e Partners' },
        { href: '/#contact_us', title: 'Contattaci' }
      ],
      classListLength: null

    }
  },

  mounted () {
    const vm = this
    const color = { code: '#28385c', rgb: '40, 56, 92', image: 'color-3', selected: 0 }
    // document.documentElement.style.setProperty('--primary-theme-color', color.code)
    // document.documentElement.style.setProperty('--primary-rgb-theme-color', color.rgb)
    vm.resizeLogo()
    window.addEventListener('resize', vm.resizeLogo)
    if (!window.tiledesk) {
      vm.loadTileDesk()
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeLogo)
  },
  methods: {
    resizeLogo () {
      window.$('#logo_img').css({ 'height': window.innerHeight > window.innerWidth ? 60 : 120 })
    },
    loadTileDesk () {
      const chatBaseURL = 'https://widget.tiledesk.com/v4/launch.js'
      const widget = document.createElement('script')
      widget.setAttribute('id', 'tiledesk-jssdk')
      document.body.appendChild(widget)
      window.tileDeskAsyncInit = function () { }
      window.tiledeskSettings = {
        projectid: process.env.tiledeskProjectId
      }

      widget.setAttribute('src', chatBaseURL)
      this.$loadScript(chatBaseURL)
        .then(() => {
          this.hideWidget()
        })
        .catch(() => {
          // Failed to fetch script
        })
    },
    hideWidget () {
      setTimeout(() => {
        if (window.tiledesk) {
          window.tiledesk.on('onInit', () => {
            if (document.getElementById('tiledesk-container').classList.contains('open')) {
              window.tiledesk.close()
            }
          })
          if (window.innerHeight > window.innerWidth) {
            window.tiledesk.on('onOpen', () => {
              document.body.classList.add('hide-overflow-y')
            })
            window.tiledesk.on('onClose', () => {
              document.body.classList.remove('hide-overflow-y')
            })
          }
        } else {
          this.hideWidget()
        }
      })
    }
  }

}

</script>

<style>
@import url('../assets/css/color/style.css');

#tiledeskiframe{
  left: 30px;
  right: 0px !important;
}

#tiledeskdiv{
  position: fixed;
  left:-10px !important;
}

#tiledesk-container{
  z-index: 1000001 !important;
}

.hide-overflow-x{
  overflow-x: hidden;
}

.hide-overflow-y{
  height: 100%;
  overflow-y: hidden;
}

#header{background: rgba(59, 114, 253, 0.9);}

</style>
