<template>
  <div id="back-to-top" style="display: none;">
    <a id="top" class="top" @click="scrollTop()" href="javascript:void(0)"> <i class="ion-chevron-up" /> </a>
  </div>
</template>
<script>
export default {
  name: 'ScrollTop',
  methods: {
    scrollTop () {
      window.$('body,html').animate({
        scrollTop: 0
      }, 800)
    }
  }
}
</script>
