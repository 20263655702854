<template>
  <div id="loading">
    <div id="loading-center">
      <!-- <div class="loader">
        <div class="cube">
          <div class="sides">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="front"></div>
            <div class="back"></div>
          </div>
        </div>
      </div> -->
      <!--      <lottie-animation-->
      <!--        :width="600"-->
      <!--        class="img-fluid"-->
      <!--        :path="animation"-->
      <!--      />-->
      <img
        :src="require('../../assets/images/animated_logo.gif')"
        style="max-width: 90%; width: 600px"
      >
    </div>
  </div>
</template>
<script>
// import LottieAnimation from 'lottie-vuejs/src/LottieAnimation'
// import Vue from 'vue'
// Vue.use(LottieAnimation)
export default {
  name: 'Loader',
  // components: {
  //   LottieAnimation
  // },
  data () {
    return {
      animationSpeed: 1,
      animation: 'animation/mtaAnimation.json'
    }
  },
  methods: {
    handleAnimation (anim) {
      this.anim = anim
    }
  }
}
</script>
