<template>
  <div class="error-page">
    <div class="container">
      <img id="errorImg" v-if="error.statusCode === 404" class="img-fluid my-5 py-5" src="../assets/images/404.png">
      <img id="errorImg" v-else class="img-fluid my-5 py-5" src="../assets/images/logo_white.png">
      <h1 class="text-center font-weight-bold text-white pb-5 mb-5">
        <div v-if="error.statusCode === 404">
          Pagina non trovata
        </div>
        <div v-else>
          Ops, si è verificato un'errore!
        </div>
      </h1>
      <nuxt-link class="d-flex justify-content-center" to="/">
        <button id="btnHome" type="button" class="btn btn-light font-weight-bold px-4 py-2">
          Torna alla home
        </button>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ['error'],
  layout: 'blog' // you can set a custom layout for the error page
}
</script>

<style scoped>
.error-page{
    background: #00446b !important;
    min-height: 100vh;
    height: 100%;
}
#btnHome{
    border-radius: 26px;
    color:#000000;
    font-size: 24px;
}
#btnHome:hover{
    background:red;
    color: white;
    border-color: red;
}
</style>
