import Vue from 'vue'
import _ from 'lodash'
import retina from 'retinajs'
import VueRetina from 'vue-retina'
import BootstrapVue from 'bootstrap-vue'
import LoadScript from 'vue-plugin-load-script'
window.skrollr = require('skrollr')
require('bootstrap')
require('magnific-popup')
require('jquery.appear')
Vue.use(BootstrapVue)
Vue.use(VueRetina, { retina })
Vue.use(LoadScript)

const components = require.context('@/components/sofbox')
_.forEach(components.keys(), (fileName) => {
  const componentConfig = components(fileName)
  const componentName = fileName.split('/').pop().split('.')[0]
  Vue.component(componentName, componentConfig.default || componentConfig)
})
